<template>
  <v-card>
    <v-card-title class="transparent-2">
      {{
        identities.length > 1
          ? `Lead API's ${identities.length}`
          : `Lead API ${identities.length}`
      }}

      <v-spacer></v-spacer>
      <v-btn @click="refresh()" :color="'primary'" icon>
        <v-icon> mdi-refresh </v-icon>
      </v-btn>
      <v-btn @click="showCreateUpdateIdentityDialog()" :color="'success'" icon>
        <v-icon> mdi-pencil </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="mt-4">
      <v-row class="my-6">
        <template v-for="(identity, index) in identities">
          <v-col cols="6" :key="index">
            <v-card
              shaped
              class="fill-height"
              elevation="1"
              @click="showCreateUpdateIdentityDialog(identity)"
            >
              <v-card-title class="transparent-1 pa-1 px-4">
                {{ identity.name }}
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  color="error"
                  @click.stop.prevent="deleteIdentity(identity)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="mt-4">
                <div class="my-2">
                  Active: {{ identity.is_active ? "Yes" : "No" }}
                </div>
                <div class="my-2">Description: {{ identity.description }}</div>
                <div
                  @click.stop.prevent
                  class="d-flex flex-row my-2 align-center"
                >
                  Code:
                  <v-spacer></v-spacer>
                  <small
                    class="pa-2"
                    style="background-color: #e3e3e3; color: black"
                  >
                    {{ identity.code }}
                  </small>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    icon
                    color="primary"
                    @click.stop.prevent="$helpers.copyText(`${identity.code}`)"
                  >
                    <v-icon small>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    icon
                    color="primary"
                    @click.stop.prevent="regenerateIdentityCode(identity)"
                  >
                    <v-icon small>mdi-arrow-u-right-bottom</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
    <template v-if="createUpdateIdentityDialog">
      <createUpdateIdentityDialog
        :dialogModel="createUpdateIdentityDialog"
        :selectedModel="selectedIdentity"
        @closeDialog="closeCreateUpdateIdentityDialog()"
        @created="closeCreateUpdateIdentityDialog()"
        @refresh="getIdentities()"
      />
    </template>
  </v-card>
</template>

<script>
import FormMaker from "@/components/FormMaker";
import createUpdateIdentityDialog from "@/components/dialogs/createUpdateIdentityDialog";
export default {
  components: {
    FormMaker,
    createUpdateIdentityDialog,
  },
  props: {},
  data() {
    return {
      createUpdateIdentityDialog: false,
      selectedIdentity: null,
      identities: [],
    };
  },
  mounted() {
    this.getIdentities();
  },
  computed: {},
  methods: {
    closeDialog(val = false) {
      this.$emit("closeDialog", val);
    },
    showCreateUpdateIdentityDialog(selectedIdentity = null) {
      this.selectedIdentity = selectedIdentity;
      this.createUpdateIdentityDialog = true;
    },
    closeCreateUpdateIdentityDialog() {
      this.selectedIdentity = null;
      this.createUpdateIdentityDialog = false;
    },
    async getIdentities() {
      let data = {};
      return await this.post(
        this.baseUrl + `/agent-portal/get-identity-list`,
        data,
        false
      ).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.identities = data?.data?.identities;
          // console.log(JSON.stringify(this.identities));
        }
      });
    },
    async regenerateIdentityCode(identity) {
      if (
        !confirm(
          "Code will be regenerated and old code can no longer be useable."
        )
      )
        return;

      if (
        !confirm(
          "Anyone with existing code will lose access to portal services."
        )
      )
        return;

      if (!confirm("Are you sure you want to regenerate code?")) return;

      let data = {
        identity_id: identity.id,
      };
      return await this.post(
        this.baseUrl + `/agent-portal/regenerate-identity-code`,
        data,
        false
      ).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.getIdentities();
          // console.log(JSON.stringify(this.identities));
        }
      });
    },
    async deleteIdentity(identity) {
      if (
        !confirm(
          "Anyone with existing code will lose access to portal services."
        )
      )
        return;

      if (!confirm("Are you sure you want to delete identity?")) return;

      let data = {
        identity_id: identity.id,
      };
      return await this.post(
        this.baseUrl + `/agent-portal/delete-identity`,
        data,
        false
      ).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.getIdentities();
          // console.log(JSON.stringify(this.identities));
        }
      });
    },
  },
};
</script>
